class SocialMediaModel {
  constructor({
    companyName = '',
    companyId = '',
    youtube = {},
    facebook = {}
  } = {}) {
    this.companyName = companyName;
    this.companyId = companyId;

    // Facebook stats
    this.totalPosts = facebook?.articles || 0;
    this.totalReactions = facebook?.likes || 0;
    this.totalComments = facebook?.comments || 0;
    this.totalShares = facebook?.shares || 0;
    this.totalStats = facebook?.total || 0;

    // YouTube stats
    this.youtubeLikes = youtube?.likes || 0;
    this.youtubeViews = youtube?.views || 0;
    this.youtubeComments = youtube?.comments || 0;
    this.youtubeVideos = youtube?.videos || 0;
    this.youtubeSubs = youtube?.subscribers || 0;
    this.youtubeEngagement = youtube?.engagement || 0;
    this.youtubeTotal = youtube?.total || 0;
  }
}

export default SocialMediaModel;
