import SocialMediaModel from './SocialMediaModel';

class IndustryReportModel {
  constructor({ industryId = '', industryName = '', report = [] } = {}) {
    this.industryId = industryId;
    this.industryName = industryName;

    // Map each company in the report to a SocialMediaModel
    this.report = report.map(
      company => new SocialMediaModel(company)
    );
  }
}

export default IndustryReportModel;